::placeholder {
  @media (prefers-color-scheme: dark) {
    color: whitesmoke;
  }
}
body {
  background: none;
  user-select: none;
  text-align: center;
  margin: 0px;
  width: 100%;
  font-size: 0;
  @media (prefers-color-scheme: dark) {
    background: black;
    color: whitesmoke;
  }
  canvas {
    width: 100%;
    cursor: default !important;
    transform: none !important;
    transition: none !important;
    transform-origin: initial !important;
  }
  #infobox {
    position: fixed;
    left: auto;
    right: 25px;
    bottom: 25px;
    text-align: right;
    font-family: sans-serif;
    font-size: 1rem;
    max-width: 768px;
    @media (max-width: 667px) {
      left: 25px;
    }
    button#form-toggle {
      background: transparent;
      border: none;
      padding: 0;
      margin: 0;
      font-size: 0;
      svg {
        @media (prefers-color-scheme: dark) {
          line {
            stroke: whitesmoke;
          }
        }
      }
    }
    .label {
      font-weight: lighter;
    }
    .hidden {
      display: none;
    }
    #heading, h1 {
      font-size: 1.66em;
      font-weight: bold;
      @media (max-width: 667px) {
        font-size: 1.25em;
      }
    }
    dialog {
      position: relative;
      background: transparent;
      color: inherit;
      border: none;
      padding: 0;
      margin: 0;
      width: 100%;
    }
    form#search {
      margin-top: 8px;
      input[type="text"] {
        border: none;
        padding: 8px;
        background: #ffffff8f;
        border-radius: 4px;
        display: block;
        box-sizing: border-box;
        text-align: right;
        width: 100%;
        @media (prefers-color-scheme: dark) {
          background: fade(white, 0.9);
          color: whitesmoke;
        }
      }
      legend {
        display: inline-block;
        @media (max-width: 667px) {
          display: block;
        }
      }
    }
    fieldset {
      border: none;
      padding: 0;
      display: inline-block;
      @media (max-width: 667px) {
        display: block;
      }
    }
    label {
      @media (max-width: 667px) {
        display: block;
      }
    }
    #legend {
      .heading {
        font-weight: bold;
      }
      .item {
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        svg, span {
          display: inline-block;
          vertical-align: middle;
        }
        svg {
          line {
            @media (prefers-color-scheme: dark) {
              stroke: whitesmoke;
            }
          }
        }
      }
    }
  }
}