@media (prefers-color-scheme: dark) {
  ::placeholder {
    color: #f5f5f5;
  }
}

body {
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  background: none;
  width: 100%;
  margin: 0;
  font-size: 0;
}

@media (prefers-color-scheme: dark) {
  body {
    color: #f5f5f5;
    background: #000;
  }
}

body canvas {
  width: 100%;
  cursor: default !important;
  transform-origin: initial !important;
  transition: none !important;
  transform: none !important;
}

body #infobox {
  text-align: right;
  max-width: 768px;
  font-family: sans-serif;
  font-size: 1rem;
  position: fixed;
  bottom: 25px;
  left: auto;
  right: 25px;
}

@media (width <= 667px) {
  body #infobox {
    left: 25px;
  }
}

body #infobox button#form-toggle {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 0;
}

@media (prefers-color-scheme: dark) {
  body #infobox button#form-toggle svg line {
    stroke: #f5f5f5;
  }
}

body #infobox .label {
  font-weight: lighter;
}

body #infobox .hidden {
  display: none;
}

body #infobox #heading, body #infobox h1 {
  font-size: 1.66em;
  font-weight: bold;
}

@media (width <= 667px) {
  body #infobox #heading, body #infobox h1 {
    font-size: 1.25em;
  }
}

body #infobox dialog {
  color: inherit;
  background: none;
  border: none;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

body #infobox form#search {
  margin-top: 8px;
}

body #infobox form#search input[type="text"] {
  box-sizing: border-box;
  text-align: right;
  background: #ffffff8f;
  border: none;
  border-radius: 4px;
  width: 100%;
  padding: 8px;
  display: block;
}

@media (prefers-color-scheme: dark) {
  body #infobox form#search input[type="text"] {
    color: #f5f5f5;
    background: #ffffff02;
  }
}

body #infobox form#search legend {
  display: inline-block;
}

@media (width <= 667px) {
  body #infobox form#search legend {
    display: block;
  }
}

body #infobox fieldset {
  border: none;
  padding: 0;
  display: inline-block;
}

@media (width <= 667px) {
  body #infobox fieldset, body #infobox label {
    display: block;
  }
}

body #infobox #legend .heading {
  font-weight: bold;
}

body #infobox #legend .item {
  vertical-align: middle;
  margin-left: 8px;
  display: inline-block;
}

body #infobox #legend .item svg, body #infobox #legend .item span {
  vertical-align: middle;
  display: inline-block;
}

@media (prefers-color-scheme: dark) {
  body #infobox #legend .item svg line {
    stroke: #f5f5f5;
  }
}

/*# sourceMappingURL=index.d74ecc14.css.map */
